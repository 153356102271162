import React from 'react'
import { Link } from 'gatsby'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      navActive: false
    }
    this.navToggle = this.navToggle.bind(this)
  }

  navToggle () {
    this.setState({
      navActive: !this.state.navActive
    })
  }

  render () {
    return (
      <nav className="c-navbar">
        <div className="container">
          <div className="row">
            <div className="c-navbar__wrapper">

              <button
                className={ this.state.navActive ? 'c-navbar__menu-toggle is-active' : 'c-navbar__menu-toggle' }
                aria-controls="js-navbar-links" type="button"
                onClick={ this.navToggle }>
                Menu
              </button>

              <div
                className={ this.state.navActive ? 'c-navbar__menu is-active' : 'c-navbar__menu' }>
                <ul className="c-navbar__list">
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/">Home</Link>
                  </li>
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/cast-and-crew">Cast &amp; Crew</Link>
                  </li>
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/competition">Competition</Link>
                  </li>
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/international-release">International Release</Link>
                  </li>
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/charity-partners">Charity Partners</Link>
                  </li>
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/merchandise">Merchandise</Link>
                  </li>
                  <li className="c-navbar__item">
                    <Link className="c-navbar__link" to="/follow-us">Follow Us</Link>
                  </li>
                  <li className="c-navbar__item">
                    <a className="c-navbar__link"
                      href="mailto:&#105;&#110;&#102;&#111;&#064;&#116;&#104;&#101;&#101;&#100;&#103;&#101;&#102;&#105;&#108;&#109;&#046;&#099;&#111;&#046;&#117;&#107;">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              <ul className="c-navbar__social-list">
                <li className="c-navbar__social-item">
                  <a className="c-navbar__social-link" href="https://twitter.com/theedgefilm2019" aria-label="Twitter" target="_blank">
                    <span className="u-visually-hide">Visit us on Twitter</span>
                    <i className="c-navbar__social-icon icon-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="c-navbar__social-item">
                  <a className="c-navbar__social-link" href="https://www.facebook.com/theedgefilm2019" aria-label="Facebook" target="_blank">
                    <span className="u-visually-hide">Visit us on Facebook</span>
                    <i className="c-navbar__social-icon icon-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="c-navbar__social-item">
                  <a className="c-navbar__social-link" href="https://www.instagram.com/theedgefilm2019/" aria-label="Instagram" target="_blank">
                    <span className="u-visually-hide">Visit us on Instagram</span>
                    <i className="c-navbar__social-icon icon-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
