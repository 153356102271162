import React from 'react'
import { Link } from 'gatsby'

import Img from 'gatsby-image'

class Footer extends React.Component {
  render () {
    return (
      <footer className="c-footer">
        <div className="container">
          <div className="row">
            <div className="c-footer__legal">
              <Img fluid={this.props.image.childImageSharp.fluid} className="c-footer__credit-image"/>

              <ul className="c-footer__legal-items">
                <li className="c-footer__legal-item">
                  <a href="https://www.noahmediagroup.com" className="c-footer__legal-link" rel="external" target="_blank">Noah Media Group</a>
                </li>
                <li className="c-footer__legal-item">
                  <Link className="c-footer__legal-link" to="/privacy-policy">Privacy and Use of Cookies</Link>
                </li>
                <li className="c-footer__legal-item">
                  <Link className="c-footer__legal-link" to="/terms-and-conditions">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
