import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../header/header'
import Footer from '../footer/footer'
import CookieConsent from 'react-cookie-consent'
import '../../styles/site.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        footerCreditImage: file(relativePath: { eq: "footer-feature-credits.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header />
        <main>{children}</main>
        <Footer image={data.footerCreditImage} />
        <CookieConsent
          cookieName="TheEdgeCookiePolicy"
          containerClasses="c-cookiebar"
          buttonClasses="c-cookiebar__btn c-btn"
          contentClasses="c-cookiebar__text"
          disableStyles={true}
        >
          We use cookies to give you the best experience of using this website.
          If you continue to browse our site, you are agreeing to receive all cookies.<br/>
          For further information, please read our <a href="/privacy-policy">cookie policy</a>.
        </CookieConsent>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
